/**
 * Plugin Utils
 *
 * Pieces borrowed from Twitter Bootstrap
 * @see https://github.com/twbs/bootstrap/blob/96ed9f7bf0edb9eecc49645891351a3240b229f6/js/src/dom/manipulator.js
 */

/**
 * Convert HTML entities into their string representation.
 *
 * @param text
 * @returns {jQuery}
 */
const htmlEntityDecode = function(text) {
    return $("<textarea>" + text + "</textarea>").text();
};

const eventToKeyCode = (e) => {
    // Apparently keyCode is deprecated and there are other "better"
    // ways to do this:
    let keyCode;
    // noinspection JSUnresolvedVariable
    switch (true) {
        case e.key !== undefined:
            keyCode = e.key.toLowerCase();
            break;
        // keyIdentifier is for Safari
        case e.keyIdentifier !== undefined:
            // noinspection JSUnresolvedVariable
            keyCode = e.keyIdentifier.toLowerCase();
            break;
        // Older browsers
        case e.keyCode !== undefined:
            keyCode = e.keyCode;
            break;
    }

    return keyCode;
};

const isEnterKeyPressed = (e) => eventToKeyCode(e) === "enter" || eventToKeyCode(e) === 13;
const isEscapeKeyPressed = (e) => eventToKeyCode(e) === "escape" || eventToKeyCode(e) === 27;

/**
 * Normalizes the data into Javascript primative types.
 *
 * @param val
 * @returns {null|boolean|number|string}
 */
const normalizeData = (val) => {
    if (val === "true") {
        return true;
    }

    if (val === "false") {
        return false;
    }

    if (val === Number(val).toString()) {
        return Number(val);
    }

    if ((val === "" || val === "null")) {
        return null;
    }

    return val;
};

/**
 * Converts camelCase into a lower-case version.
 *
 * @param key
 * @returns {string}
 */
const normalizeDataKey = key => key.replace(/A-Z/g, chr => chr.toLowerCase());

/**
 * Return an object of normalized data attributes for {element}.
 *
 * @param element
 * @returns {object}
 */
const getDataAttributes = (element) => {
    let attributes = {};

    if (!element) {
        return attributes;
    }

    $(element).each((idx, el) => attributes = {...attributes, ...el.dataset});
    Object.keys(attributes).forEach(key => attributes[key] = normalizeData(attributes[key]));

    return attributes;
};

const getElementName = (elements) => {
    const names = [];

    $(elements).each(function(idx, element) {
        let name = element.localName;

        if (element.id) {
            name += `#${element.id}`;
        }

        if (element.classList && element.classList.length) {
            const classes = [];
            for (const value of element.classList.values()) {
                classes.push(value);
            }
            name += `.${classes.join(".")}`;
        }

        names.push(name);
    });

    return names.join(", ");
};

module.exports = {
    getDataAttributes,
    getElementName,
    htmlEntityDecode,
    isEnterKeyPressed,
    isEscapeKeyPressed,
    normalizeData,
    normalizeDataKey,
};

$.fn.select2.amd.define("select2/data/paginateAdapter", ["select2/data/array", "select2/utils", "select2/diacritics"],
    function (ArrayData, Utils, DIACRITICS) {
        let elementOptions = null;

        function CustomData($element, options) {
            CustomData.__super__.constructor.call(this, $element, options);
            elementOptions = options;
        }

        Utils.Extend(CustomData, ArrayData);

        //this is a basic implementation of the matcher search, since the default matcher doesn't work when "ajax" data is passed in.
        function filter(queryTerm, value) {
            const normalize = text => text.replace(/[^\u0000-\u007E]/g, a => DIACRITICS[a] || a).toLowerCase();
            const terms = $.trim(queryTerm).split(" ").map(normalize);

            // If the return is false that means all of the terms exist in parts
            if (terms.some(term => !normalize(value).includes(term))) {
                return true;
            }
            return false;
        }

        CustomData.prototype.query = function (params, callback) {
            if (!("page" in params)) {
                params.page = 1;
            }
            let pageSize = params.pageSize ?? 30;
            let optgroupIndex = null;
            let items = {};
            let children = {};
            const queryTerm = params.term ?? "";

            $.each(this.$element.children(), function (i, elem) {
                if ($(elem).prop("tagName") === "OPTGROUP" && queryTerm.length <= 3) {
                    children = {};
                    optgroupIndex = i;
                    items[i] = {text: $(elem).attr("label")};
                }
                else {
                    if (optgroupIndex !== null) {
                        if (!("children" in items[optgroupIndex])) {
                            items[i] = $.extend(items[optgroupIndex], {children: []});
                        }
                        items[optgroupIndex]["children"].push({
                            id: $(elem).attr("value"),
                            text: elem.innerText
                        })
                    }
                    else {
                        const filterMethod = elementOptions.options.customFilter || filter;
                        const queryTermLength = elementOptions.options.queryTermLength || 3;
                        if ((queryTerm.length >= queryTermLength && !filterMethod(queryTerm, elem.innerText)) || queryTerm.length <= queryTermLength) {
                            items[i] = {
                                id: $(elem).attr("value"),
                                text: elem.innerText
                            }
                        }
                    }
                }
            });
            let finalItems = Object.values(items);
            let data = {};
            data.results = finalItems.slice((params.page - 1) * pageSize, params.page * pageSize);
            data.pagination = {};
            data.pagination.more = params.page * pageSize < finalItems.length;
            callback(data);
        };

        return CustomData;
    });

import {getElementName, getDataAttributes} from "./plugin.utls.jquery";
import "select2";
import "./select2.paginate.adapter";
import "./select2.unccminimuminput";

const defaultType = "info";
const icons = new Map([
    ["success", "fa-check-circle"],
    ["warning", "fa-exclamation-triangle"],
    ["danger", "fa-bug"],
    ["info", "fa-info-circle"]
]);

const getAlertIcon = (type) => {
    return icons.has(type)
        ? icons.get(type)
        : icons.get(defaultType);
};

export function camelToSnake(str) {
    return str.replace(/[A-Z]/g, l => `_${l.toLowerCase()}`);
}

export function camelToKebab(str) {
    return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}

export function loadAlert(message, opts = {
    alertType: "info",
    container: "#flash-messages",
    fixed: false,
    timeout: 8000
}) {
    const template = $("#alert-template");
    const options = $.extend({alertType: "info", container: "#flash-messages", fixed: false, timeout: 8000}, opts);

    if (template.length === 0) {
        console.warn("Could not locate the #alert-template");
        return;
    }

    const alert = $(template.html());
    const icon = alert.find("strong > i.fa");
    icon.addClass(getAlertIcon(options.alertType));

    alert.find(".message").html(message);
    alert.addClass(`alert-${options.alertType}`);

    if (options.fixed) {
        alert.addClass("fixed-top col-sm-8 offset-sm-2 col-12");
    }

    if (options.timeout && Number(options.timeout).toString() === options.timeout.toString() && options.timeout > 0) {
        const alertTimeout = setTimeout(function () {
            alert.slideUp();
            alert.remove();
        }, options.timeout);

        alert.find("[data-dismiss=alert]")
            .on("click", function () {
                clearTimeout(alertTimeout);
                alert.slideUp();
            });
    }

    alert.appendTo(options.container).slideDown();
}

export function loadSpinner(message = undefined) {
    let loader = $("#tempLoader");

    if (loader.length === 0) {
        loader = $("<div id='tempLoader' class='loading'></div>");
        $("body").append(loader);
    }

    if (typeof message === "string" && message.length > 0) {
        // If message is provided, we need to create/update the markup to display
        // the message and insert it before the loader.
        let loaderMessage = $("#tempLoaderMessage");

        if (loaderMessage.length === 0) {
            loaderMessage = $("<div id='tempLoaderMessage' class='loading-message'><span class='text'></span></div>");
            loaderMessage.insertBefore(loader);
        }

        loaderMessage.html(message + "&hellip;");
    }
}

export function unloadSpinner() {
    $("#tempLoader").remove();
    $("#tempLoaderMessage").remove();
}

export function clearAlerts(alertType = undefined, container = "#flash-messages") {
    alertType = typeof alertType === "undefined" || alertType === null ? "" : alertType;
    $(`[class*=alert-${alertType}].alert`, `${container}`).remove();
}

export function initializeToolTips(elements, opts = {}) {
    const tooltipElements = elements || $('[data-toggle="tooltip"], [data-tooltip="tooltip"]');
    tooltipElements.tooltip($.extend({
        "html": true
    }, opts));
    tooltipElements.click(function () {
        tooltipElements.tooltip("hide");
    });
}

export function initializeDatePicker(elements, opts = {}) {
    const dateElements = elements || $(".date-picker");
    dateElements.datepicker($.extend({
        format: "mm/dd/yyyy"
    }, opts));
}

let totalSize = 0;
export function initializeFileUpload(element, opts = {}, maxTotalFileSize = 20480) {
    const maxTotalSize = maxTotalFileSize * 1024;

    element.fileinput($.extend({
        theme: "explorer-fas",
        allowedFileExtensions: ["pdf", "doc", "docx", "tiff", "jpg", "jpeg", "gif", "png"],
        autoOrientImage: false,
        maxFileCount: 10,
        maxFileSize: 20000,
        showCancel: false,
        showUpload: false,
        autoReplace: true,
        browseOnZoneClick: true,
        showCaption: true,
    }, opts));

    element.on("filebeforeload", (event, file, index) => {
        if ((totalSize + file.size) > maxTotalSize) {
            element.fileinput("_showError", `The file "${file.name}" exceeds the 20M cap on file uploads.`, {}, 'filebeforeupload');
            return false;
        }

        totalSize += file.size;
        return true;
    }).on("filepreremove", (event, id, index) => {
        const stack = element.fileinput("getFileStack");
        $.each(stack, (idx, file) => {
            if (idx === index) {
                totalSize -= file.size;
            }
        });
    });
}

export async function initializeWYSIWYGEditors(elements, plugins = [], opts = {}) {
    $.trumbowyg.svgPath = false;
    $.trumbowyg.hideButtonTexts = true;
    let buttons = [
        ['viewHTML'],
        ['strong', 'em'],
        ['superscript', 'subscript'],
        ['link'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
        ['fullscreen']
    ];
    $.each(plugins, function (index) {
        $.merge(buttons, [[index]])
    });

    function _initiateElements() {
        elements.trumbowyg($.extend({
            btns: buttons,
            plugins: plugins,
            urlProtocol: true,
            autogrow: true,
            removeformatPasted: true,
            btnsDef: {
                mention: {
                    text: "Insert placeholder text"
                }
            }
        }, opts));

        if (typeof plugins.mention !== "undefined") {
            // Work-around issue where menu opens up offscreen
            const t = $.trumbowyg;
            const p = t.defaultOptions.prefix;

            const $dropdown = $("[data-" + p + "dropdown=mention]");

            $(".trumbowyg-mention-button").on("mousedown.trumbowyg-event", function(e) {
                $dropdown.css({
                    left: $dropdown.offset().left - $dropdown.innerWidth(),
                });
            });
        }
    }

    _initiateElements();
}

export function isJson(data) {
    try {
        JSON.parse(data);
    } catch {
        return false;
    }
    return true;
}

export function loadMiniSpinner(element) {
    unloadMiniSpinner(element)
    element.addClass("single-mini-spin");
}

export function unloadMiniSpinner(element) {
    element.removeClass("single-mini-spin");
}

export function validateRequired(formId) {
    let success = true;
    $("input,select,textarea", "#" + formId).each(function () {
        $(this).removeClass("is-invalid");
        if ($(this)[0].hasAttribute("required") && $.trim($(this).val()) === "") {
            $(this).addClass("is-invalid");
            success = false;
        }
    });
    return success;
}

export function noSpaces(element) {
    element.on({
        keydown: function (e) {
            if (e.which === 32) {
                return false;
            }
        },
        change: function () {
            $(this).val($(this).val().replace(/\s/g, ""));
        }
    })
}

export function confirmationModal(message, confirmCallback, declineCallback, headicon = "fa-exclamation-circle") {
    $("#confirmation-modal").detach();
    const modalStr = `
        <div id="confirmation-modal" class="modal fade" data-backdrop="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header confirm-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body confirm-body">
                        <div class="confirm-icon-box">
                            <i class="fa fa-5x ${headicon}"></i>
                       </div>
                        <h4 class="col-12">Are you sure?</h4>
                        <div class="confirm-body-passed">${message}</div>
                    </div>
                    <div class="modal-footer confirm-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="modal-deny">No</button>
                        <button type="button" class="btn btn-primary" id="modal-confirm">Yes</button>
                    </div>
                </div>
            </div>
        </div>`;

    const modal = $(modalStr);
    modal.appendTo("body").modal("show");

    $("#modal-confirm").click(function () {
        confirmCallback.call(this);
        modal.modal("hide");
    });

    $("#modal-deny").click(function () {
        if (declineCallback !== undefined) {
            declineCallback.call(this);
        }
    });

    modal.on("hidden.bs.modal", function () {
        modal.detach();
    });
}

export function toggleExpandable() {
    const expandedClass = "__expanded__";
    let startHeight, finalHeight;

    startHeight = $(this).height();

    if (!$(this).hasClass(expandedClass)) {
        $(this).data("height", startHeight);
    }

    $(this).css("height", $(this).hasClass(expandedClass) ? $(this).data("height") : "auto");
    finalHeight = $(this).height();

    $(this).height(startHeight);
    $(this).animate({height: finalHeight}, 500, () => $(this).toggleClass(expandedClass));
}

export function willContentsWrap(element) {
    element = $.clone($(element).get(0));
    // Hiding the element will cause the width and scrollWidth to be zero.
    // Instead, break it out of the page flow and visually hide it.
    $(element).css({
        position: "absolute",
        visibility: "hidden",
        zIndex: -1,
        opacity: 0
    });

    $(element).appendTo("body");

    // Round up to avoid floating-point discrepancies.
    const width = Math.ceil($(element).width());
    const scrollWidth = Math.ceil($(element).css({"white-space": "nowrap"}).get(0).scrollWidth);

    $(element).remove();

    return scrollWidth > width;
}

export function formatCurrency(amount) {
    // NumberFormat will happily format 'NaN' as a currency and thus we must not allow NaN types to go through.
    return isNaN(amount) ? "-" : Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    }).format(amount);
}

export function initializePopovers(elements = undefined, opts = {}) {
    $(elements ?? "[data-toggle=popover]").each(function () {
        const element = $(this);
        const selector = element.data("content-selector");

        if (selector && selector.length > 0) {
            opts.content = $(`#${selector}`).text();
            opts.sanitize = false;
        }

        element.popover({
            html: true,
            ...opts
        });
    });
}

export async function initializeSelect2(elements = undefined, opts = {}) {
    if (!(elements instanceof jQuery)) {
        opts = elements;
        elements = "[data-toggle=select2]";
    }

    await $(elements).each(async function () {
        const name = getElementName(this);
        const data = getDataAttributes(this);

        // Lazy-load the dependencies so that they can be easily managed here instead
        // of everywhere they are needed.
        await import("select2");
        import("select2/dist/css/select2.css");

        if (data["allowClear"] && !data["placeholder"]) {
            // We really don't want to inadvertently deploy a broken dropdown, so make sure the error is in the dev's
            // face.
            loadAlert("There was an error initializing the web page. If the problem persists please contact the OneIT Helpdesk.", {
                alertType: "danger",
                container: $("#flash-messages"),
                timeout: 8000
            });
            console.error(`The select2 dropdown ${name} with data-allow-clear is missing required data-placeholder`);
        }

        const DIACRITICS = await import("select2/src/js/select2/diacritics.js");
        const normalize = text => text.replace(/[^\u0000-\u007E]/g, a => DIACRITICS[a] || a).toLowerCase();

        // @see https://select2.org/configuration/options-api
        let query = {};
        let options = {
            width: "resolve",
            templateResult: (item, container) => {
                $(container).attr("title", item.text);

                const term = query.term ?? '';
                if (item.loading || term.length < 3) {
                    return item.text;
                }

                const mark = (text, term) => {
                    const matchStart = normalize(text).indexOf(normalize(term));
                    const matchEnd = matchStart + term.length;

                    if (matchStart < 0) {
                        return text;
                    }

                    const result = $(`<span class="select2-rendered__match"></span>`);
                    const match = $(`<span class="select2-match__term"></span>`).text(text.substring(matchStart, matchEnd));

                    result.text(text.substring(0, matchStart));
                    result.append(match);
                    result.append(text.substring(matchEnd));

                    return result;
                };

                return mark(item.text, term);
            },
            language: {
                searching: (params) => {
                    query = params;
                    return 'Searching...';
                },
            },
            matcher: (params, data) => {
                const queryTerm = params.term ?? "";

                if (queryTerm.length < 3) {
                    return data;
                }

                const select2Matcher = $.fn.select2.defaults.defaults.matcher;
                // This does an exact match on the entire term.
                const result = select2Matcher(params, data);

                if (result && data.children && result.children && data.children.length !== result.children.length) {
                    const children = data.children.filter(child => queryTerm ? normalize(child.text).includes(normalize(queryTerm)) : true);
                    result.children = children.length > 0 ? children : data.children;
                } else if (result === null) {
                    // See if all of the given words in the search term exist in the data text
                    // e.g., health services should match "Health & Human Services", but
                    //       health science does not match "Health & Human Services"
                    const terms = $.trim(queryTerm).split(" ").map(normalize);
                    // If the return is false that means all of the terms exist in parts
                    if (!terms.some(term => !normalize(data.text).includes(term))) {
                        return data;
                    }
                }

                return result;
            },
            paginateAdapter: false,
            ...opts,
            ...data
        };
        if (options.paginateAdapter === true) {
            $.extend(options, {dataAdapter: $.fn.select2.amd.require("select2/data/paginateAdapter"), ajax: {}});
        }

        if (options.theme === "bootstrap") {
            import("select2-theme-bootstrap4/dist/select2-bootstrap.css");
        }

        $(this).select2(options);
        // @see https://github.com/select2/select2/issues/5993
        $(document).on("select2:open", () => document.querySelector('.select2-container--open .select2-search__field').focus());
    });
}

/**
 * @param iframe
 * @param uris - this should be an array of uri values that make up the whole url.
 */
export function loadChartIFrameSrc(iframe, uris) {
    $(iframe).attr("src", uris.join("/"));
    window.open($(iframe).attr("src"), $(iframe).prop("id"), "");
}

export function scrollToFirstError() {
    //try catch here because occasionally if there is a lot of validation on the form, the "offset" throws an error - this will suppress that error
    try {
        $("html, body").animate({
            scrollTop: $(".is-invalid:visible:first").length ? $(".is-invalid:visible:first").offset().top - 40 : $(".fa-exclamation-triangle:visible:first").offset().top - 60
        }, 200);
        return true;
    } catch (err) {
        return false;
    }
}

// noinspection JSUnusedGlobalSymbols
export default {
    camelToKebab,
    camelToSnake,
    clearAlerts,
    confirmationModal,
    formatCurrency,
    initializeDatePicker,
    initializeFileUpload,
    initializePopovers,
    initializeSelect2,
    initializeToolTips,
    initializeWYSIWYGEditors,
    isJson,
    loadAlert,
    loadChartIFrameSrc,
    loadMiniSpinner,
    loadSpinner,
    noSpaces,
    scrollToFirstError,
    toggleExpandable,
    unloadMiniSpinner,
    unloadSpinner,
    validateRequired,
    willContentsWrap,
};

$.fn.select2.amd.define("select2/data/unccMinimumInputLength", [

], function () {
    function UnccMinimumInputLength (decorated, $e, options) {
        this.minimumInputLength = options.get('minimumInputLength');

        decorated.call(this, $e, options);
    }

    UnccMinimumInputLength.prototype.query = function (decorated, params, callback) {
        params.term = params.term || '';

        const isBannerId = params.term !== '' && !isNaN(params.term);

        if ( (isBannerId && params.term.length < 8) || params.term.length < this.minimumInputLength) {
            this.trigger('results:message', {
                message: 'inputTooShort',
                args: {
                    minimum: isBannerId ? 8 : this.minimumInputLength,
                    input: params.term,
                    params: params
                }
            });

            return;
        }

        decorated.call(this, params, callback);
    };

    return UnccMinimumInputLength;
});